@font-face {
    font-family: 'Gilmer';
    src: url('/assets/fonts/Gilmer-Light.woff') format('truetype');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'Gilmer';
    src: url('/assets/fonts/Gilmer-Regular.woff') format('truetype');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'Gilmer';
    src: url('/assets/fonts/Gilmer-Medium.woff') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Gilmer';
    src: url('/assets/fonts/Gilmer-Bold.woff') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Gilmer';
    src: url('/assets/fonts/Gilmer-Heavy.woff') format('truetype');
    font-weight: 500;
    font-style: normal;
}