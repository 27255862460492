@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");
@import './fonts.scss';
@import './variables';
@import './mixins.scss';

.repeat-form-row-actions {
    width: 70px;
    height: 35px;
    display: flex;
    gap: 10px;
    padding: 0 10px;

    .button {
        padding: 0;
        &.add {
            color: $LT_BLUE;

            &:disabled {
                color: $WHITE_45P;
            }
        }
    }
}

.two-columns {
    display: grid;
    grid-template-areas: "a a";
    grid-gap: 10px;
    align-items: flex-start;

    &.equal {
        grid-auto-columns: 1fr;
    }

    &.auto {
        grid-auto-columns: 1fr auto;

        &.left {
            grid-auto-columns: auto 1fr;
        }
    }
}

.three-columns {
    display: grid;
    grid-template-areas: "a a a";
    grid-gap: 10px;
    align-items: flex-start;

    &.equal {
        grid-auto-columns: 1fr;
    }

    &.auto {
        grid-auto-columns: 1fr auto;

        &.left {
            grid-auto-columns: auto 1fr;
        }
    }
}

.four-columns {
    display: grid;
    grid-template-areas: "a a a a";
    grid-gap: 10px;
    align-items: flex-start;

    &.equal {
        grid-auto-columns: 1fr;
    }

    &.auto {
        grid-auto-columns: 1fr auto;

        &.left {
            grid-auto-columns: auto 1fr;
        }
    }
}


.multi-columns {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    gap: 10px;
}

.required-instructions {
    @include required-fields-instructions;
}

.spaced-right {
    align-self: flex-end;
    margin-left: 12px;
}

.spaced-left {
    align-self: flex-end;
    margin: 0 auto 0 0;
}

.label-container {
    display: flex;
    align-items: flex-end;
    app-tooltip {
        padding-bottom: 5px;
    }
}

.resize-none {
    resize: none;
}