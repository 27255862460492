@import 'variables';

.tox-tinymce {
    border-radius: 5px !important;
    border: 0 !important;
}

.tox .tox-menubar, .tox .tox-toolbar, .tox .tox-statusbar {
    border-bottom: 1px solid $GRAY_DK !important;
    background: none !important;
    background-color: $GRAY_MD !important;
}

.tox-edit-area__iframe {
    border-bottom: 1px solid $GRAY_DK !important;
    background-color: $GRAY_47 !important;
}


.tox .tox-mbtn:not(:hover):not(:focus):not(.tox-mbtn--active), 
.tox .tox-tbtn:not(:hover):not(:focus):not(.tox-tbtn--active),
.tox .tox-tbtn.tox-tbtn--select.tox-tbtn--active:not(:hover),
.tox .tox-statusbar a, .tox .tox-statusbar__path-item, .tox .tox-statusbar__wordcount {
    color: $WHITE !important;
}

.tox .tox-tbtn:not(:hover):not(:focus):not(.tox-tbtn--active):not(.tox-tbtn--enabled) svg,
.tox .tox-tbtn.tox-tbtn--select.tox-tbtn--active:not(:hover) svg,
.tox-collection__item-caret:not(:hover):not(:focus) svg,
.tox-button:not(:hover):not(:focus) svg {
    fill: $WHITE !important;
}

.tox:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type) {
    border-right: 1px solid $GRAY_DK !important;
}

.tox .tox-statusbar {
    border: none !important;
}

.tox-collection__item:not(.tox-collection__item--state-disabled), 
.tox-collection__item:not(.tox-collection__item--state-disabled) .tox-collection__item-accessory {
    color: $WHITE !important;
    background-color: $DD_GRAY !important;
}

.tox-collection__item--state-disabled,
.tox-collection__item--state-disabled .tox-collection__item-accessory,
.tox-collection__item--state-disabled .tox-collection__item-caret svg {
    color: $GRAY_XLT !important;
    fill: $GRAY_XLT !important;
}

.tox .tox-menu {
    background-color: $DD_GRAY !important;
    border: 0 !important;
}

.tox .tox-dialog-wrap__backdrop {
    background-color: $MODAL_BACKDROP_BLACK !important;
}

.tox .tox-dialog,
.tox .tox-dialog__header,
.tox .tox-dialog__footer {
    background-color: $GRAY_DK !important;
    color: $WHITE !important;
    border: 0 !important;
    border-radius: $border-radius !important;
}
.tox .tox-form__group {
    margin-bottom: 10px !important;
}
.tox .tox-label {
    font-size: $font-size-12 !important;
    color: $WHITE !important;
    padding-bottom: 5px !important;
    font-family: "Gilmer", sans-serif !important;
    font-weight: 200 !important;
}

.tox .tox-textfield {
    font-size: $font-size-12 !important;
    color: $WHITE !important;
    background-color: $GRAY_MD !important;
    border: 0 !important;
    padding: 10px !important;
    border-radius: $border-radius !important;
    font-family: "Gilmer", sans-serif !important;
    font-weight: 200 !important;
}

.tox-form__controls-h-stack>:not(:first-child) {
    margin-left: 10px !important;
}

.tox-form__controls-h-stack>:not(:last-child) {
    width: 100% !important;
}

.tox-button.tox-button:not(.tox-button--icon) {
    font-size: $font-size-12;
    padding: 12px 0;
    font-family: "Gilmer", sans-serif ;
    font-weight: 200 ;
    border-radius: $border-radius;
    min-width: 135px;
    line-height: unset;
    border: 0;
}
.tox-button:not(.tox-button--secondary):not(.tox-button--icon) {
    background-color: $LT_BLUE !important;
}

.tox-button.tox-button--secondary {
    background-color: $GRAY_LT !important;
    color: $WHITE !important;
}

.tox-dialog__footer .tox-dialog__footer-end>*{
    margin-left: 12px;
}