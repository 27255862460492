/* In an isolated file because it is used on worker efficiency scatter plot, on the cluster marker call out and map search */
.entity-list-container {
    position: relative;
    width: 100%;
    max-height: 250px;
    overflow: auto;
    .header {
        position: sticky;
        top: 0;
        display: flex;
        flex-flow: row nowrap;
        padding: 10px;
        background-color: $W_LT_BLUE;
        font-size: $font-size-14;
        color: $WHITE;
        &-close {
            position: absolute;
            top: 4px;
            right: 10px;
            font-size: $font-size-16;
        }
    }
    .list {
        background: $ALMOST_BLACK;
        .item {
            display: flex;
            flex-flow: row nowrap;
            padding: 10px;
            height: 50px;
            align-items: center;
            cursor: pointer;
            &-image-container {
                display: flex;
                justify-content: center;
                width: 50px;
                padding-right: 10px;
                margin-right: 10px;
                border-right: 1px solid white;
                img {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    border: 1px solid $WHITE_65P;
                }

                .image-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background: white;
                    i {
                        color: $W_DK_GRAY;
                    }
                }
    
                .color-dot {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 30px;
                    width: 30px;
                    border-radius: 50%;
                    app-icon {
                        font-size: $font-size-12;
                    }
                }
    
                .logo {
                    display: flex;
                    flex-shrink: 0;
                    img {
                        max-width: 100%;
                        max-height: 30px;
                        max-width: 30px;
                        display: flex;
                    }
                }
            }
    
            .name-container {
                display: flex;
                flex-flow: column nowrap;
                flex: 1;
                overflow: hidden;
                .name {
                    font-size: $font-size-12;
                    font-weight: $font-weight-500;
                    @include text-ellipsis;
                }
                .sub-name {
                    font-size: $font-size-12;
                    color: $MED_GRAY;
                    @include text-ellipsis;
                }
            }

            &:nth-child(odd) {
                background-color: $GRAY_MD;
            }
        }
    }
}

.teams, .teams-dark {
    .entity-list-container {
        &::-webkit-scrollbar-track {
            background: var(--map-callout-bkg-color);
        }

        .header {
            background-color: var(--map-callout-bkg-color);
            color: var(--map-callout-color);
            padding: 15px 20px;
        }

        .list {
            background-color: var(--map-callout-bkg-color);

            .item {
                height: 60px;

                &-image-container {
                    border-right-color: var(--hyperlink-color);

                    img {
                        border-color: var(--map-callout-color);
                    }

                    .image-icon {
                        background-color: var(--map-marker-avatar-bkg-color);

                        i {
                            color: var(--icon-color);
                        }
                    }
                }

                .name-container {
                    .name {
                        font-size: 14px;
                        font-weight: 500;
                        color: var(--map-callout-color);
                    }
                    .sub-name {
                        font-size: 12px;
                        font-weight: 200;
                        color: var(--map-callout-color);
                    }
                }

                &:nth-child(odd) {
                    background-color: var(--map-callout-bkg-color);
                }
            }
        }
    }
}
