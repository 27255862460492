@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?13383523');
  src: url('../font/fontello.eot?13383523#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?13383523') format('woff2'),
       url('../font/fontello.woff?13383523') format('woff'),
       url('../font/fontello.ttf?13383523') format('truetype'),
       url('../font/fontello.svg?13383523#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?13383523#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-radio:before { content: '\e800'; } /* '' */
.icon-walt:before { content: '\e801'; } /* '' */
.icon-radio-dudes:before { content: '\e802'; } /* '' */
.icon-wind:before { content: '\e803'; } /* '' */
.icon-pressure-vessel:before { content: '\e804'; } /* '' */
.icon-drum:before { content: '\e805'; } /* '' */
.icon-tank:before { content: '\e806'; } /* '' */
.icon-snow:before { content: '\e807'; } /* '' */
.icon-sleet:before { content: '\e808'; } /* '' */
.icon-rain:before { content: '\e809'; } /* '' */
.icon-partly-cloudy-night:before { content: '\e80a'; } /* '' */
.icon-partly-cloudy-day:before { content: '\e80b'; } /* '' */
.icon-fog:before { content: '\e80c'; } /* '' */
.icon-cloudy:before { content: '\e80d'; } /* '' */
.icon-clear-night:before { content: '\e80e'; } /* '' */
.icon-clear-day:before { content: '\e80f'; } /* '' */
.icon-map-marker:before { content: '\e810'; } /* '' */
.icon-site-vision:before { content: '\e811'; } /* '' */
.icon-connections-add:before { content: '\e812'; } /* '' */
.icon-connections-pending:before { content: '\e813'; } /* '' */
.icon-connections:before { content: '\e814'; } /* '' */
.icon-whistle:before { content: '\e815'; } /* '' */
.icon-unlink:before { content: '\e816'; } /* '' */
.icon-square-bolt:before { content: '\e817'; } /* '' */
.icon-unpinned:before { content: '\e818'; } /* '' */
.icon-wisp:before { content: '\e819'; } /* '' */
.icon-console:before { content: '\e81a'; } /* '' */
