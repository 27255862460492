@import 'nouislider/distribute/nouislider.min.css';
@import 'variables.scss';

app-gas-levels-slider nouislider,
app-range-slider nouislider {
    margin: 0 !important;
    .noUi-handle {
        box-shadow: 0 3px 6px -3px $BLACK;
        &::before, &::after {
            width: 0;
        }
        .handle-label {
            position: absolute;
            left: 0;
            right: 0;
            text-align: center;
            line-height: 37px;
            font-size: 12px;
            font-weight: 200;
        }
    }
    .noUi-horizontal .noUi-handle {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        top: -13px;
    }
    .noUi-target, .noUi-connects {
        border-radius: 8px;
        border: 0.5px solid $GRAY_LT;
        background: transparent;
    }

    .noUi-origin:nth-child(2) .noUi-handle {
        background-color: $ORANGE;
    }
    .noUi-origin:nth-child(3) .noUi-handle {
        background-color: $RED;
    }
    .noUi-connect:nth-child(1) {
        background-color: $GREEN;
    }
    .noUi-connect:nth-child(2) {
        background-color: $ORANGE;
    }
    .noUi-connect:nth-child(3) {
        background-color: $RED;
    }

    &.lower-limits {
        .noUi-origin:nth-child(2) .noUi-handle {
            background-color: $RED;
        }
        .noUi-origin:nth-child(3) .noUi-handle {
            background-color: $ORANGE;
        }
        .noUi-origin:nth-child(4) .noUi-handle {
            background-color: $ORANGE;
        }
        .noUi-origin:nth-child(5) .noUi-handle {
            background-color: $RED;
        }

        
        .noUi-connect:nth-child(1) {
            background-color: $RED;
        }
        .noUi-connect:nth-child(2) {
            background-color: $ORANGE;
        }
        .noUi-connect:nth-child(3) {
            background-color: $GREEN;
        }
        .noUi-connect:nth-child(4) {
            background-color: $ORANGE;
        }
        .noUi-connect:nth-child(5) {
            background-color: $RED;
        }
    }

    &.invert-colors {
        .noUi-origin:nth-child(3) .noUi-handle {
            background-color: $GREEN;
        }
        .noUi-connect:nth-child(1) {
            background-color: $RED;
        }
        .noUi-connect:nth-child(3) {
            background-color: $GREEN;
        }

        &.lower-limits {
            .noUi-origin:nth-child(2) .noUi-handle {
                background-color: $GREEN;
            }
            .noUi-origin:nth-child(5) .noUi-handle {
                background-color: $GREEN;
            }

            
            .noUi-connect:nth-child(1) {
                background-color: $GREEN;
            }
            .noUi-connect:nth-child(3) {
                background-color: $RED;
            }
            .noUi-connect:nth-child(5) {
                background-color: $GREEN;
            }
        }
    }

    &.no-colors {
        .noUi-origin .noUi-handle { 
            background-color: $LT_BLUE;
            border: 1px solid $DK_GRAY;
        }
        .noUi-connect {
            background-color: $GRAY_DK;
        }
    }
}